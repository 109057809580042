<template>
  <div>
    <div class="reward-card-main-image">
      <img v-if="deepGet(rewardsCard, 'config.main_image')" :src="deepGet(rewardsCard, 'config.main_image')" class="w-100" />
    </div>

    <div class="s-p-3 overflow-x-hidden">
      <b-carousel
        ref="rewardsCardCarousel"
        v-model="slide"
        :interval="0"
        :indicators="cards.length > 1"
        :no-wrap="true"
        class="reward-card__carousel mb-4"
      >
        <b-carousel-slide v-for="card in cards" :key="card.id">
          <template #img>
            <b-card class="border border-1 reward-card" no-body>
              <div
                class="
                  reward-card__header
                  px-3
                  d-flex
                  justify-content-between
                  align-items-center
                "
              >
                <div class="reward-card__title">{{ title }}</div>
                <div class="d-flex align-items-center">
                  <span class="reward-card__header__point">{{ availablePoints }}</span>
                  <span class="small ml-2">點</span>
                </div>
              </div>

              <div class="reward-card__body p-2">
                <div
                  v-for="i in rewardsCard.point_number"
                  :key="i"
                  class="reward-card__body__point p-2"
                  :class="i <= card.point? 'active' : ''"
                  :style="pointWidth"
                >
                  <div
                    class="
                      reward-card__body__point__stamp
                      d-flex
                      justify-content-center
                      align-items-center
                    "
                  >
                    <img v-if="i <= card.point" src="./images/stamp.svg" />
                    <span v-else-if="i <= card.point + card.usedPoint" class="s-text-xs">已使用</span>
                    <span v-else>{{ i }}</span>
                  </div>
                </div>
              </div>
            </b-card>
          </template>
        </b-carousel-slide>
        <button
          v-if="cards.length > 1"
          :disabled="slide === 0"
          href="#"
          role="button"
          class="carousel-control-prev"
          @click="prev"
        >
          <span class="carousel-control-prev-icon"></span>
          <span class="sr-only">Next slide</span>
        </button>
        <button
          v-if="cards.length > 1"
          :disabled="slide === cards.length - 1"
          href="#"
          role="button"
          class="carousel-control-next"
          @click="next"
        >
          <span class="carousel-control-next-icon"></span>
          <span class="sr-only">Next slide</span>
        </button>
      </b-carousel>

      <ShopSelection
        ref="shopSelection"
        v-model="shopId"
        v-if="shouldDisplayBranchList"
        :branch-provider="branchProvider"
        :show-branch-code="showBranchCode"
        :placeholder="displayPlaceholder"
        @loading="handleBranchesAreLoading"
        @loaded="handleBranchesAreLoaded"
        @selectedShop="handleShopIsSelected"
      />

      <div v-if="fetchSuccess" class="reward-award mb-3">

        <div class="reward-tabs">
          <a
            class="reward-tab"
            v-for="tab in tabs"
            :key="tab.key"
            :class="{ 'active-tab': query.tab && query.tab === tab.key }"
            @click="query.tab = tab.key"
          >
            {{ tab.label }}
          </a>
        </div>

        <template v-if="query.tab=='gifts'">
          <b-card
            v-for="gift in gifts"
            :key="gift.id"
            class="reward-award-item d-flex flex-nowrap flex-row mt-3"
            no-body
          >
            <div class="reward-award-item__image" :class="{ 'reward-award-item__image--empty': !gift.image }">
              <img class="img-fluid" :src="gift.image" />
            </div>
            <div class="flex-fill p-2 d-flex flex-column">
              <div class="reward-award-item__title flex-fill">
                {{ gift.name }}
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="reward-award-item__point d-flex align-items-center">
                  <div class="reward-award-item__stamp mr-2">
                    <img src="./images/stamp.svg" />
                  </div>
                  <div>{{ gift.point }}</div>
                </div>

                <template v-if="showRewardBtn">
                  <button
                    v-if="!doesFindProduct(gift.target_id)"
                    class="
                      s-btn s-liff-theme-btn-outline s-btn-xs
                      reward-award-item__button
                    "
                    disabled
                  >
                    查無商品
                  </button>
                  <button
                    v-else-if="!giftIsInStock(gift)"
                    class="
                      s-btn s-liff-theme-btn-outline s-btn-xs
                      reward-award-item__button
                    "
                    disabled
                  >
                    庫存不足
                  </button>
                  <button
                    v-else-if="gift.point > point"
                    class="
                      s-btn s-liff-theme-btn-outline s-btn-xs
                      reward-award-item__button
                    "
                    disabled
                  >
                    點數不足
                  </button>
                  <button
                    v-else
                    class="
                      s-btn s-liff-theme-btn-outline s-btn-xs
                      reward-award-item__button
                    "
                    @click="handleExchange(gift)"
                    :disabled="!isValid"
                  >
                    兌換
                  </button>
                </template>
              </div>
            </div>
          </b-card>

          <PointPagination
            v-if="fetchSuccess"
            v-model="page"
            :totalPage="totalPage"
          ></PointPagination>
        </template>
        <template v-else-if="query.tab=='received'">
          <ExchangeRecords v-if="eventCodeSetted" :event-code="eventCode"></ExchangeRecords>
        </template>
        <template v-else-if="query.tab=='point-logs'">
          <PointLogs v-if="eventCodeSetted" :event-code="eventCode" :force-source-type="'rewards_card_point'"></PointLogs>
        </template>
      </div>

      <Loading v-if="fetching" />

      <Popup ref="popupConfirm" panelHeight="auto">
        <div slot="title">
          是否確定要使用
          <span class="s-primary">{{ popup.point }}</span>
          點
        </div>
        <div v-if="branchCode" class="s-mb-5 s-text-center">
          兌換店號：{{ branchCode }}
        </div>
        <div class="s-mb-5 s-text-center">
          請注意，點數一經使用即無法取消
        </div>
        <div slot="actions" class="s-flex s-space-x-2">
          <button
            class="s-btn s-liff-theme-btn-outline s-btn-sm"
            type="button"
            @click="$refs.popupConfirm.close()"
          >
            取消
          </button>
          <button
            class="s-btn s-liff-theme-btn s-btn-sm"
            type="button"
            @click="handleConfirmExchange"
          >
            確定
          </button>
        </div>
      </Popup>

      <Popup ref="popupBranchCode" panelHeight="auto">
        <div slot="title">請輸入店號</div>
        <div class="s-mb-5 s-text-center">
          請輸入店號以完成兌換
        </div>
        <input
          v-model="branchCode"
          class="s-mb-5 s-text-center branch-code-input"
          placeholder="請輸入店號"
        />
        <div slot="actions" class="s-flex s-space-x-2">
          <button
            class="s-btn s-liff-theme-btn-outline s-btn-sm"
            type="button"
            @click="$refs.popupBranchCode.close()"
          >
            取消
          </button>
          <button
            class="s-btn s-liff-theme-btn s-btn-sm"
            type="button"
            :disabled="!branchCode"
            @click="handleBranchCodeConfirm"
          >
            確定
          </button>
        </div>
      </Popup>

      <b-modal
        id="modal-product"
        hide-footer
        hide-header
        centered
        bodyClass="p-0"
      >
        <div v-if="selectProdcut" :style="colorObject" class="purchase">
          <div class="p-3 border-bottom">
            <div class="row">
              <div class="col-4">
                <div class="photo">
                  <img
                    :src="selectProdcut.image"
                    class="img-fluid mr-2"
                  />
                </div>
              </div>
              <div class="col-8">
                <div class="h1">{{ selectProdcut.title }}</div>
                <div class="point d-flex align-items-center">
                  <div class="reward-award-item__stamp mr-2">
                    <img src="./images/stamp.svg" />
                  </div>
                  <div>{{ selectProdcut.point }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="p-3">
            <b-button
              v-for="sku in selectProdcut.skus"
              :key="sku.id"
              variant="outline-dark"
              class="m-2"
              :class="{ 'active': selectSku && selectSku.id === sku.id }"
              @click="selectSku = sku"
              :disabled="stockCheckIsEnabled && sku.quantity === 0"
            >
              {{ sku.sku_name }}
            </b-button>
          </div>

          <div class="p-3">
            <input
              v-if="needBranchCode"
              v-model="branchCode"
              class="form-control s-mb-5 s-text-center"
              placeholder="請輸入店號"
            />
          </div>

          <div class="p-3">
            <div class="row">
              <div class="col">
                <b-button size="lg" variant="secondary" class="w-100" @click="$bvModal.hide('modal-product')">
                  取消
                </b-button>
              </div>
              <div class="col">
                <b-button
                  size="lg"
                  variant="primary"
                  class="w-100"
                  @click="handleConfirmExchange"
                  :disabled="!selectSku"
                >
                  確定
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>

    <div v-if="fetchSuccess" class="reward-notice s-p-3">
      <div class="reward-notice__title">注意事項</div>
      <div v-html="rewardsCard.memo"></div>
    </div>

    <coupon-modal ref="couponModal" :coupon="selectedCoupon" :confirm-from-outside="true" @confirmed="handleStartExchange" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PointPagination from "@/pages/Liff/Point/PointPagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import couponApi from "@/apis/liff/v2/coupon";
import pointApi from "@/apis/liff/v2/point";
import rewardsCardApi from "@/apis/liff/v2/rewards-card";
import Popup from "@/components/Page/Liff/Shared/Popup";
import Loading from "@/components/Page/Liff/Shared/Loading";
import ShopSelection from "@/components/Page/Liff/Branch/ShopSelection";
import CouponModal from "@/components/Page/Liff/CouponList/CouponModal.vue"
import mixinStaff from "@/mixins/liff/staff";
import themeColor from "@/mixins/liff/themeColor";
import _ from "lodash";
import PointLogs from "@/components/Page/Liff/Point/PointLogs";
import ExchangeRecords from "@/components/Page/Liff/Point/ExchangeRecords";
import * as consts from "@/consts";
import {
  set,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
} from "date-fns";
import deepGet from "lodash/get";

export default {
  mixins: [mixinStaff, themeColor],
  components: {
    Popup,
    PointLogs,
    ExchangeRecords,
    PointPagination,
    Loading,
    ShopSelection,
    CouponModal,
  },
  data() {
    return {
      rewardsCard: {
        title: "",
      },
      fetchSuccess: false,
      slide: 0,
      currentGift: null,
      selectProdcut: null,
      selectSku: null,
      gifts: [],
      popup: {
        point: 0,
      },
      fetching: false,
      eventCode: null,
      eventCodeSetted: false,
      page: 1,
      totalPage: 1,
      tabs: [
        { key: "gifts", label: "兌換獎項" },
        { key: "received", label: "兌換紀錄" },
        { key: "point-logs", label: "點數紀錄" },
      ],
      shopId: null,
      query: {
        tab: this.$route.query.tab || "gifts",
      },
      currentTab: "gifts",
      productsOfSelectedShop: [],
      branchCode: "",
      usedPoint: 0,
      availablePoints: '-',
      selectedCoupon: {},
    };
  },
  created() {
    this.query = updateQueryFromRoute(
      this.$route,
      this.$store,
      this.query
    );
  },
  computed: {
    ...mapState({
      user: (state) => state.liffAuth.user,
    }),
    isValid() {
      if (!this.fetchSuccess) {
        return false;
      }

      const now = new Date();
      let startAt = this.rewardsCard.start_at;
      startAt = set(new Date(startAt), {
        hours: String(getHours(new Date(startAt))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(startAt))).padStart(2, "0"),
      });
      let endAt = this.rewardsCard.end_at;
      endAt = set(new Date(endAt), {
        hours: String(getHours(new Date(endAt))).padStart(2, "0"),
        minutes: String(getMinutes(new Date(endAt))).padStart(2, "0"),
      });

      return isBefore(startAt, now) && isAfter(endAt, now);
    },
    title() {
      if (!this.fetchSuccess) {
        return;
      }

      const title = this.rewardsCard.title || "集點卡";

      return this.isValid ? title : `（無法使用）${title}`;
    },
    formattedAvailablePoints() {
      return this.availablePoints?.point?.toLocaleString("en-US");
    },
    point() {
      return typeof this.availablePoints === "number"
        ? this.availablePoints
        : 0;
    },
    pointNumber() {
      return this.rewardsCard?.point_number || 10;
    },
    cards() {
      const totalPoint = this.point + this.usedPoint;

      return totalPoint > 0
        ? Array.from({ length: Math.ceil(totalPoint / this.pointNumber) }, (_, i) => {
          const availablePointCards = Math.floor(this.point / this.pointNumber)
          let cardPoint;
          if (i < availablePointCards) {
            cardPoint = this.pointNumber;
          } else if (i === availablePointCards) {
            cardPoint = this.point % this.pointNumber;
          } else {
            cardPoint = 0;
          }

          let cardUsedPoint = i < Math.floor(totalPoint / this.pointNumber) ? this.pointNumber : totalPoint % this.pointNumber;
          cardUsedPoint -= cardPoint;

          return {
            id: i,
            point: cardPoint,
            usedPoint :cardUsedPoint,
          }
        })
        : [0];
    },
    shouldDisplayBranchList() {
      return !! _.get(this.rewardsCard, 'config.gift_section.branch_list.enable', false);
    },
    branchProvider() {
      return _.get(this.rewardsCard, 'config.gift_section.branch_list.branch_provider', "");
    },
    displayPlaceholder() {
      return _.get(this.rewardsCard, 'config.gift_section.branch_list.default_select_text')
    },
    showBranchCode() {
      return _.get(this.rewardsCard, 'config.gift_section.branch_list.show_branch_code', 0) === 1
    },
    stockCheckIsEnabled() {
      return !! _.get(this.rewardsCard, 'config.gift_section.enable_product_stock', false)
    },
    useShopProduct() {
      // 只有當啟用使用庫存＋分店下拉時，才需要檢查該分店是否有此商品
      return this.shouldDisplayBranchList && this.stockCheckIsEnabled
    },
    needBranchCode() {
      return _.get(this.rewardsCard, 'config.check_type', 'default') === 'need_branch_code'
    },
    showRewardBtn() {
      return !this.shouldDisplayBranchList || this.shopId !== null
    },
    pointWidth() {
      return {width:`${100/Math.round(this.rewardsCard.point_number/(this.rewardsCard.point_number > 4?2:1))}%`}
    },
  },
  watch: {
    page() {
      this.fetchGifts();
    },
    query: {
      handler(value) {
        updateUrl(value, this.$store, this.$router);
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.$route.params.rewardsCardId) {
      await this.fetchRewardsCard();
    }

    const checkRegisteredPemission = this.deepGet(this.rewardsCard, 'config.permissions', []).includes('registered')

    if (checkRegisteredPemission && !this.user.registered_at) {
      this.$router.push({
        name: "LiffRegisterIndex",
        params: { orgCode: this.$route.params.org_code },
      });
      return;
    } else {
      this.isLoading = false
    }

    this.eventCode = this.rewardsCard?.event?.code || this.$route.query.event || null;
    this.eventCodeSetted = true;

    if (this.fetchSuccess) {
      this.fetchBranches();
      this.fetchAvailablePoints();
      this.fetchUsedPoints({ eventCode: this.eventCode });
      this.fetchGifts();
    }
  },
  methods: {
    deepGet,
    ...mapActions("liffPoint", [
      "fetchExpiringSoonPoints",
    ]),
    handleBranchesAreLoading() {
      this.fetching = true;
    },
    handleBranchesAreLoaded() {
      this.fetching = false;
    },
    async handleShopIsSelected() {
      this.fetching = true;
      const res = await rewardsCardApi.listProductsOfShop(this.$route.params.rewardsCardId, this.shopId)
      this.productsOfSelectedShop = res.data
      this.fetching = false;
    },
    async fetchRewardsCard() {
      try {
        this.rewardsCard = await rewardsCardApi.getRewardsCard(
          this.$route.params.rewardsCardId
        );
        this.fetchSuccess = true;
      } catch (error) {
        console.error(error);
        this.fetchSuccess = false;
      }
    },
    async fetchAvailablePoints() {
      try {
        const info = await pointApi.getAvailablePoints(this.eventCode, { force_source_type: 'rewards_card_point' })
        console.debug("[fetch available points]", info)
        this.availablePoints = info.point;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchBranches() {
      if (false === this.shouldDisplayBranchList) {
        return;
      }

      try {
        await this.$refs.shopSelection.fetchBranchesOptions();
      } catch (e) {
        if (!this.branchProvider) {
          this.$swal("請設定分店提供者", "", "warning");
        } else {
          console.error(e)
        }
      }
    },
    async fetchGifts() {
      if (this.$route.params.rewardsCardId) {
        await this.fetchGiftsForRewardsCard();
      } else {
        await this.fetchGiftsForGeneral();
      }
    },
    async fetchGiftsForRewardsCard() {
      this.fetching = true;
      try {
        let res = await rewardsCardApi.listGifts(
          this.$route.params.rewardsCardId,
          this.page
        );
        let gifts = res.data;
        this.totalPage = res.meta.last_page;

        if (!gifts || !gifts.length) {
          this.gifts = [];
          return;
        }

        this.gifts = this.normalizeGifts(gifts);
      } catch (error) {
        console.error(error);
      } finally {
        this.fetching = false;
      }
    },
    async fetchUsedPoints() {
      if (! this.rewardsCard.show_used_point) {
        return;
      }

      try {
        const resp = await pointApi.getUsedPoints(this.eventCode);
        this.usedPoint = resp.point
      } catch (error) {
        console.error(error);
      }
    },
    normalizeGifts(gifts) {
      return gifts.map((g) => ({
        ...g,
        subtitle: g.data?.sub_title || "",
        badge: g.data?.badge || "",
        point: g.point_need,
        image: g.image_url || "",
      }));
    },
    async fetchGiftsForGeneral() {
      this.fetching = true;
      try {
        let res = await pointApi.listGifts(this.eventCode, this.page);
        let gifts = res.data;
        this.totalPage = res.meta.last_page;

        if (!gifts || !gifts.length) {
          this.gifts = [];
          return;
        }

        this.gifts = this.normalizeGifts(gifts);
      } catch (error) {
        console.error(error);
      } finally {
        this.fetching = false;
      }
    },
    findProductInShop(productId) {
      return this.productsOfSelectedShop.find(product => product.id === productId || product.parent_product_id === productId) || null;
    },
    doesFindProduct(productId) {
      // 不需要檢查分店的商品時，直接回傳 true
      if (!this.useShopProduct) {
        return true;
      }

      const product = this.findProductInShop(productId);

      return product && product.skus.length;
    },
    giftIsInStock(gift) {
      if (!this.stockCheckIsEnabled) {
        return true;
      }

      if (!this.shouldDisplayBranchList) {
        return gift.quantity > 0
      }

      const productInShop = this.findProductInShop(gift.target_id);

      return !! productInShop?.skus?.some(sku => sku.quantity > 0);
    },
    handleExchange(gift) {
      this.popup.point = (gift.point ?? 0).toLocaleString("en-US");
      this.currentGift = gift;

      switch (gift.target_type) {
        case "product":
          this.handleExchangeProduct(gift);
          break;
        case "redeem_code_bundle":
          this.handleExchangeCoupon(gift);
          break;
        default:
          this.handleStartExchange();
      }
    },
    // 如果想兌換的獎項是商品：
    handleExchangeProduct(gift) {
      this.selectProdcut = this.useShopProduct
        // 分店商品
        ? this.findProductInShop(gift.target_id)
        // 一般商品，這邊是為了統一格式，做了一層轉換
        : {
          image: gift.image,
          title: gift.name,
          skus: gift.skus,
        };
      this.selectProdcut.point = gift.point;
      this.selectSku = this.selectProdcut.skus.length == 1 ? this.selectProdcut.skus[0] : null;
      this.$bvModal.show("modal-product");
    },
    // 如果想兌換的獎項是 Coupon (redeem_code)
    async handleExchangeCoupon(gift) {
      if (gift.target.usage_type === consts.REDEEM_CODE_BUNDLE_USAGE_TYPES.IMMEDIATE) {
        this.fetching = true;
        try {
          this.selectedCoupon = await couponApi.getTempCouponFromRedeemCodeBundle(gift.target.id);
          this.$refs.couponModal.open();
        } catch (error) {
          let errorMessage =error.response?.data.message;

          if (errorMessage) {
            this.$swal({
              title: "兌換失敗！",
              text: typeof errorMessage === 'string' ? errorMessage : Object.values(errorMessage).flat(),
              icon: "error",
              button: "確定",
            });
          } else {
            console.error(error);
          }
        }
        this.fetching = false;

        return;
      }

      this.handleStartExchange();
    },
    // 兌換商品時，不會走到這個函示
    handleStartExchange() {
      this.$refs.popupConfirm.close();
      this.$refs.popupBranchCode.close();

      let needBranchCode = false;

      // 如果 reward_card 本身要分店核銷，或是 coupon(redeem_code) 要分店核銷且使用，則跳出分店代碼輸入頁面
      if (
        this.currentGift.target_type === 'redeem_code_bundle' &&
        this.currentGift.target.usage_type === consts.REDEEM_CODE_BUNDLE_USAGE_TYPES.IMMEDIATE
      ) {
        needBranchCode = true;
      } else if (this.needBranchCode) {
        needBranchCode = true;
      }

      if (needBranchCode) {
        this.$refs.popupBranchCode.open();
        return;
      }

      this.$refs.popupConfirm.open();
    },
    handleBranchCodeConfirm() {
      this.$refs.popupBranchCode.close();
      this.$refs.popupConfirm.open();
    },
    async handleConfirmExchange() {
      try {
        this.fetching = true;

        if (this.$route.params.rewardsCardId) {
          await rewardsCardApi.redeemGift(this.$route.params.rewardsCardId, {
            gift_id: this.currentGift.id,
            sku_id: this.selectSku?.id,
            branch_code: this.branchCode,
            branch_id: this.shopId,
          });
        } else {
          await pointApi.redeemGift(this.eventCode, this.currentGift);
        }

        this.$swal({
          title: "兌換完成！",
          icon: "success",
          button: "確定",
        }).then(() => {
          this.fetchGifts();
        });
      } catch (error) {
        let errorMessage =error.response.data.message;

        this.$swal({
          title: "兌換失敗！",
          text: typeof errorMessage === 'string' ? errorMessage : Object.values(errorMessage).flat(),
          icon: "error",
          button: "確定",
        });
        console.log(error.response.data);
      } finally {
        this.fetching = false;
        this.$refs.popupConfirm.close();
        this.$bvModal.hide("modal-product");

        this.branchCode = null;
        this.fetchAvailablePoints({ eventCode: this.eventCode });
        this.fetchUsedPoints({ eventCode: this.eventCode });
        this.fetchExpiringSoonPoints({ eventCode: this.eventCode });
      }
    },
    next() {
      this.$refs.rewardsCardCarousel.next();
    },
    prev() {
      this.$refs.rewardsCardCarousel.prev();
    },
  },
};
</script>

<style lang="scss" scoped>
.reward-card {
  border-radius: 0.5rem;
  overflow-x: hidden;

  ::v-deep &__carousel {
    //min-height: 260px;
    //overflow: hidden;
    .carousel-inner {
      overflow: visible;
    }

    .carousel-caption {
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
    }

    .carousel-item {
      // min-height: 260px;
      margin-right: -100% !important;
    }

    .carousel-control-prev,
    .carousel-control-next {
      z-index: 10;
      padding: 5px !important;
      width: auto;
      box-shadow: 0px 4px 4px rgba(44, 44, 46, 0.15);
      background: #ffffff;
      border: 1px solid #e5e5ea;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 50%;
      top: 55%;
      bottom: auto;
      opacity: 1;
      transform: translateY(-50%);

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    .carousel-control-prev {
      left: -8px;
    }

    .carousel-control-next {
      right: -8px;
    }

    .carousel-control-next-icon {
      transform: translateX(1px);
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.69434 7.99707C8.69434 8.22559 8.60645 8.43652 8.43066 8.6123L1.87402 15.0195C1.71582 15.1777 1.51367 15.2656 1.28516 15.2656C0.819336 15.2656 0.458984 14.9053 0.458984 14.4307C0.458984 14.2021 0.546875 14 0.696289 13.8418L6.68164 7.99707L0.696289 2.14355C0.546875 1.99414 0.458984 1.7832 0.458984 1.55469C0.458984 1.08887 0.819336 0.728516 1.28516 0.728516C1.51367 0.728516 1.71582 0.816406 1.87402 0.974609L8.43066 7.38184C8.60645 7.55762 8.69434 7.75977 8.69434 7.99707Z' fill='black'/%3E%3C/svg%3E%0A");
      background-size: 9px 16px;
    }

    .carousel-control-prev-icon {
      transform: translateX(-1px);
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.305664 7.99707C0.305664 7.75977 0.402344 7.55762 0.578125 7.38184L7.13477 0.974609C7.28418 0.816406 7.48633 0.728516 7.71484 0.728516C8.18945 0.728516 8.5498 1.08887 8.5498 1.55469C8.5498 1.7832 8.45312 1.99414 8.30371 2.14355L2.31836 7.99707L8.30371 13.8418C8.45312 14 8.5498 14.2021 8.5498 14.4307C8.5498 14.9053 8.18945 15.2656 7.71484 15.2656C7.48633 15.2656 7.28418 15.1777 7.13477 15.0195L0.578125 8.6123C0.402344 8.43652 0.305664 8.22559 0.305664 7.99707Z' fill='black'/%3E%3C/svg%3E%0A");
      background-size: 9px 16px;
    }

    .carousel-indicators {
      overflow: auto;
      bottom: -2rem;

      > li {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        background-color: var(--s-primary);
        border-radius: 100%;
      }
    }
  }

  &__header {
    border-top-color: var(--s-primary) !important;
    border-top-width: 5px !important;
    font-weight: 600;
    border-bottom: solid 1px #e5e5ea;

    &__point {
      font-size: 30px;
      color: var(--s-primary);
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;

    &__point {
      opacity: 0.5;

      &__stamp {
        aspect-ratio: 1/1;
        border: dashed 2px #c0c4cc;
        background-color: #f2f2f7;
        color: #979797;
        border-radius: 100%;
        font-size: 20px;
        font-weight: 600;
        max-width: 60px;
        min-width: 50px;
        margin: auto;
      }

      &__date {
        font-size: 12px;
        text-align: center;
        color: #979797;
        visibility: hidden;
      }
    }

    &__point.active {
      opacity: 1;

      .reward-card__body__point__stamp {
        //content: url(images/stamp.svg);
        background-color: var(--s-primary);
        border: none;
        box-shadow: 0px 4px 4px rgba(44, 44, 46, 0.15);
        padding: 15px;
      }

      .reward-card__body__point__date {
        visibility: visible;
      }
    }

    //     &__point {
    //       &.active {
    //         content: '<svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M14 2L17.708 9.512L26 10.724L20 16.568L21.416 24.824L14 20.924L6.584 24.824L8 16.568L2 10.724L10.292 9.512L14 2Z" fill="white" stroke="#FBFBFB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    // </svg>';
    //       }
    //     }
  }
}

.reward-award {
  &__title {
    font-weight: 600;
    font-size: 16px;
  }

  &-item {
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: solid 1px #e5e5ea;

    &__image {
      aspect-ratio: 1/1;
      flex: 0 0 35%;

      &--empty {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #DDD;
        color: #888;

        &:before {
          content: 'no image';
        }
      }
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
    }

    &__point {
      font-size: 20px;
      font-weight: 600;
      color: var(--s-primary);
    }

    &__stamp {
      background-color: var(--s-primary);
      border-radius: 1rem;
      display: inline-block;
      padding: 5px;
      aspect-ratio: 1/1;
      line-height: 0;

      img {
        width: 10px;
        height: 10px;
      }
    }

    &__button {
      font-size: 13px;
      line-height: 18px;
      padding: 6px 16px;
      font-weight: 600;
      width: auto;

      &:disabled {
        border: solid 1px #c0c4cc !important;
        background-color: #e5e5ea;
        color: #2c2c2e !important;
        opacity: 1;
      }
    }
  }
}

.reward-notice {
  border-top: solid 1px #e5e5ea;
  font-size: 14px;
  font-weight: 400;
  color: #636366;

  &__title {
    font-weight: 600;
    font-size: 16px;
  }

  ol {
    list-style: decimal;
    padding: inherit;

    li {
      margin-bottom: 0.5rem;
    }

    li::marker {
      color: var(--s-primary);
      font-size: 600;
    }
  }
}

.reward-tabs {
  display: flex;
  background-color: var(--s-white);
  position: sticky;
  top: 0;
  z-index: 10;
  .reward-tab {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
    border-bottom: 1px solid #e5e5ea;

    color: var(--liff-tab_inactive_color) !important;

    // &.router-link-exact-active, &.active {
    //   border-bottom: 2px solid var(--liff-tab-color);
    //   color: var(--liff-tab-color);
    // }

    &.active-tab {
      border-bottom: 2px solid var(--liff-tab-color);
      color: var(--liff-tab-color) !important;
    }
  }
}

::v-deep.purchase {
  max-width: 768px;

  h1,
  .h1 {
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
  }

  .photo {
    margin-top: -30%;
    aspect-ratio: 1/1;
    img {
      border-radius: 8px;
      width: 100%;
    }
  }

  .point {
    font-size: 15px;
    font-weight: 600;
    color: var(--s-primary);
  }

  .btn.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }
}

.branch-code-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #e5e5ea;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s;

  &:focus {
    border-color: var(--s-primary);
    box-shadow: 0 0 0 2px rgba(var(--s-primary-rgb), 0.2);
  }

  &::placeholder {
    color: #c0c4cc;
  }
}
</style>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
